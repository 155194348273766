import React from 'react';
import { graphql } from 'gatsby';
import Layout3 from '../components/layout';
import Styles from "./mdlist.module.css";



// const VersionWrap: React.FunctionComponent<LayoutProps> = (props,{ children }) => {
//   return (
//     <VersionContent
//     version={props.version}
//     date={props.date}
//    >
//       <>

//         {children}

//       </>
//     </VersionContent>
//   );
// };

// const VersionContent = props => (
//   <div>
//   <hr/>
//           <h2>第{props.version}稿  - {props.date}</h2><br/>

//           <h3>
//             プレビューページのデザイン改善
//           </h3>
//           <p>
// <li>典型例・国土のプレビューの幅を広げました。
// </li>
// <li>典型例・国土のプレビューの下に凡例を入れました。</li>
// <li>解説を表示するスペースにスクロールバーを設けました。</li>
// <li>都市選択の初期表示を赤色にしました。</li>
// <li><a href="/101"><b>プレビューページをご確認ください</b></a></li>
//           </p>

//           <h3>
//             プレビューページの読み込みタイミング変更
//           </h3>
//           <p>
//           <li>タブを開いた時に読みこむよう設定しました。</li>
//           <li><a href="/101"><b>プレビューページをご確認ください</b></a></li>
//           </p>

//           <h3>
//             プレビューページの読み込みタイミング変更
//           </h3>
//           <p>
//           <li>タブを開いた時に読みこむよう設定しました。</li>
//           <li><a href="/101"><b>プレビューページをご確認ください</b></a></li>
//           </p>

//           <br/>
// </div>
// )




const HomePage = (props: any) => {
  const { data } = props;


  return (
    <Layout3>
        <div style={{margin: "30px"}}>

          <h1>更新情報</h1>
          <p>ご確認いただき、ありがとうございます。</p>
{/* 
<VersionWrap version="23" date="1/24">

aaa


</VersionWrap>

<VersionContent
 version="23"
 date="1/24"
>
  aaa
</VersionContent> */}

<hr/>
          <h2>第29稿  - 2/12</h2><br/>

          <div style={{color:"red"}}>
          <h3>
            作業中の箇所
          </h3>
          <p>
            <li>18,106,9014,9019,9020以外はデータ更新作業中です。</li>
            <li>Cesium全画面、Cesium２画面（比較するページ）について調整中です。</li>
          </p>
          </div>

          <h3>
            セシウムの機能を追加しました
          </h3>
          <p>
            <li>擬似3Dマウス機能、拡大縮小機能、初期視点に戻る機能、時系列選択機能、データ表示のONOFF機能、背景地図切替機能、ピン固定機能、を追加しました。


            {/* </li>
            <li> */}

            <iframe width="560" height="315" src="https://www.youtube.com/embed/2Yb8ddrJhds" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>

            </li>
            
          </p>

          <h3>
            凡例を見直しました
          </h3>
          <p>
            <li>凡例のデザインを見直しました。</li>
            <li>余白などの微調整がページごとにできるようにしました。</li>
          </p>

          <h3>
            サイトの構成を修正しました
          </h3>
          <p>
            <li>タブデザインに変更しました。ページ分けしており初回読み込みが軽量です。また、ページ遷移の際には共通部分を再読み込みしません。さらに、マウスカーソルをタブの上に載せたタイミングで先読みしており、切り替えも高速です。</li>
            <li>詳細情報、関連データについて、各ページで参照できるようになりました。</li>
          </p>

          <h3>
            スマホ、タブレット対応に向けた改修を始めました。
          </h3>
          <p>
            <li>タッチ操作、縦方向の画面に対応しました。
            </li>
            <li>画面サイズに応じて段組を自動切り替えするようにしました。今後様々な機種での動作確認にご協力のほどよろしくお願いいたします。
            </li>
            <li><a href="/9019/erabu"><b>以上について、各ページにて動作等のご確認をよろしくお願いします。</b></a></li>
          </p>

          <h3>
            画像読み込みの高速化処理を始めました。
          </h3>
          <p>
          <li>画像の読み込みを高速化するようにしました。テーマを選ぶ画面には適用していませんので、比較してみてください。</li>
          <li><a href="/"><b>表紙をご確認ください</b></a></li>
          </p>


          <h3>
            データを更新しました
          </h3>
          <p>
            <li>18,106,9014,9019,9020を整備しました。</li>
            <li><a href="/mdlist"><b>登録情報一覧をご確認ください</b></a></li>
          </p>


<hr/>
          <h2>第25稿  - 1/27</h2><br/>

          <h3>
            テーマの選択画面を修正しました
          </h3>
          <p>
          <li>地理的事象の分類から選ぶ、を追加しました。</li>
          <li>目次、を単元に修正しました。</li>
          <li><a href="/themeset"><b>テーマの選択画面をご確認ください</b></a></li>
          </p>

          <h3>
            表紙を設置しました
          </h3>
          <p>
          <li>背景はランダムに切り替わりますのでリロードしてみてください。</li>
          <li><a href="/landing"><b>表紙をご確認ください</b></a></li>
          </p>

          <h3>
            プレビューページのデザインを改善しました
          </h3>
          <p>
            <li>凡例の色を修正しました。
            </li>
            <li><a href="/101"><b>プレビューページをご確認ください</b></a></li>
          </p>


          <h3>
            セシウムを一部改善しました
          </h3>
          <p>
            <li>凡例を追加しました。</li>
            <li><a href="/101/erabu"><b>101をご確認ください</b></a></li>
            <li>そのほかのセシウムの開発状況は<a href="/cesiumtest"><b>こちらにて確認できます</b></a></li>
          </p>


          <h3>
            データを更新しました
          </h3>
          <p>
            <li>14を改良して9014を、203を改良して9203を追加しました。</li>
            <li><a href="/mdlist"><b>登録情報一覧をご確認ください</b></a></li>
          </p>




<hr/>
          <h2>第23稿  - 1/24</h2><br/>

          <h3>
            プレビューページのデザインを改善しました
          </h3>
          <p>
<li>並び順を変更しました（①国土俯瞰→②典型例→③自分で自治体を選ぶ→④2都市を比較する）。
</li>
<li>典型例・国土のプレビューの幅を広げました。
</li>
<li>典型例・国土のプレビューの下に凡例を入れました。</li>
<li>解説を表示するスペースにスクロールバーを設けました。</li>
<li>都市選択の初期表示を赤色にしました。</li>
<li><a href="/101"><b>プレビューページをご確認ください</b></a></li>
          </p>

          <h3>
            プレビューページを軽量化しました
          </h3>
          <p>
          <li>それぞれのタブを開いた時に、データを読みこむよう設定しました。</li>
          <li><a href="/101"><b>プレビューページをご確認ください</b></a></li>
          </p>

          <h3>
            地理総合、地理探究の目次を設置しました
          </h3>
          <p>
          <li>地理総合と地理探究の目次（素案）を設置しました。</li>
          <li><a href="/chirisogo"><b>地理総合 -目次-</b></a></li>
          <li><a href="/chiritankyu"><b>地理探究 -目次-</b></a></li>
          </p>

          <h3>
            kmlの増
          </h3><p>
          　・14,18,106のkmlが時系列対応になりました。<br/>
          　・<a href="/mdlist"><b>登録情報一覧</b></a>よりご確認ください。
          </p>


          <br/>

<hr/>
          <h2>第22稿  - 1/21</h2><br/>        
          <h3>
            国土を俯瞰する（全国の傾向を見る）、の画面が使えるようになりました
          </h3>
          <p>
          <li>各ページに「国土を俯瞰する」画面を追加しました。</li>
          <li><a href="/101"><b>テーマ101</b></a>にサンプルデータを掲載しています。</li>
          </p>
          <h3>
            上記以外のCesium関係は作業中継続中です
          </h3>
<li>上記以外のセシウム関係作業は継続中です（年次の表記、ナビゲーション、データON/OFF、２画面での年次連動）</li>

          <br/>

<hr/>
          <h2>第21稿  - 1/20</h2><br/>        
          <h3>
            圏域（複数市町村）のサンプルを掲載しました
          </h3>
          <p>
<li><a href="/101"><b>テーマ101</b></a>にて、市町村入力画面から「福岡２市」「福岡５市」「福岡都市圏」「福岡県」が選べるようになりました。</li>
<li>表示速度などご確認ください。なお回線速度は関係なくPC速度に依存します。<a href="/101"><b>テーマ101はこちらから</b></a></li>
          </p>
          <h3>
            全国データのダウンロードができるようになりました（隠しコマンド）
          </h3>
          <p>
<li>全てのデータで、市町村入力欄から「全国」が選択できるようになりました。</li>
<li>データチェックや圏域の検討にお使いください。（プレビューが使えませんので、隠しコマンドとしています）</li>
          </p>
          <br/>

<hr/>
          <h2>第20稿  - 1/19</h2><br/>        
          <h3>
            画面の構成の変更等
          </h3>
          <p>
<li>タイトル幅の縦幅を縮小しました。</li>
<li>１画面に全部が収まるように割付を変更しました。</li>
<li>TRY!とプレビュー画面が同時に見えるようにしました。</li>
<li>左上のメニューは削除しました。</li>
<li>解説は典型例のブロックに移動しました。</li>
<li>都市の選択ボタンを仮配置しました（現在は全画面の再読み込みしますが、セシウムのみ再読み込みする機能とする予定です）。</li>
<li>2画面表示のKMLダウンロードのリンクを修正しました。</li>
<li>GoogleMapへのリンクを復活しました。</li>
<li>凡例に高さと色の出典を入れました。</li>
<li>タイトルの横に、高さ×色を表示しました。</li>
<li>現在データのある202のページを作成しました。SDGs2へのリンクが使えるようになっています。</li>

          </p>

          <br/>

<hr/>
          <h2>第19稿  - 1/18</h2><br/>        
          <h3>
            登録情報の更新
          </h3>
          <p>
          　・教科書の目次をアップデートしました。<br/>
          　　<a href="/mdlist/#lecture"><b>登録情報一覧 - 教科書の目次</b></a>
          よりご確認ください。<br/>
          　・SDGsテーマをアップデートしました。<br/>
          　　<a href="/mdlist/#sdgs"><b>登録情報一覧 - SDGs</b></a>
          よりご確認ください。
          </p>
          <h3>
            個別ページのデザイン更新
          </h3><p>
          　・左側のメニューがお使いいただけるようになりました。<br/>
          　　<a href="/105"><b>こちらなど各ページからご確認ください</b></a></p>
          <h3>
            新セシウムの検証（２）
          </h3><p>
          　・ナビゲーションが使えるようになりました。<br/>
          　　<a href="/cesiumtest"><b>こちらの画面</b></a>から「全画面表示」にお進みください。
</p>
          <h3>
            新セシウムの検証（３）
          </h3><p>
          　・ランディング、データのON/OFFなどが使えるようになりました。<br/>
          　　<a href="/cesiumtest"><b>こちらの画面</b></a>から「ランディング、データのON/OFFなどはこちらから」にお進みください。
</p>
<h3>
            <font style={{color:"red"}}>都市を選び直した際には再読み込みが必要です</font>
          </h3><p>
          　・都市を選び直したあと、リロード（F5ボタン）でプレビュー画面を更新してください。
          <br />
          </p>


          <br/>

          <hr/>

          <h2>第18稿  - 1/15</h2><br/>        
          <h3>
            登録情報の更新
          </h3><p>
          　・現時点での情報を登録しました。<br/>
          　・<a href="/mdlist"><b>登録情報一覧</b></a>よりご確認ください。
          </p>
          <h3>
            kmlの増
          </h3><p>
          　・200番台のkmlを追加しました。<br/>
          　・<a href="/mdlist"><b>登録情報一覧</b></a>よりご確認ください。
          </p>
          <h3>
            個別ページのデザイン更新
          </h3><p>
          　・メニューを仮設置しました（まだ機能しません。デザイン確認用です）。<br/>
          　・「典型的な都市」の紹介枠を設置しました。<br/>
          　・本文の修飾を試行しました。<br/>
          　　<a href="/105"><b>こちらなど各ページからご確認ください</b></a>
          
          </p>
          <h3>
            新Cesiumの検証
          </h3><p>
          　・先週公開された新Cesiumへの更新に向けて検証中<br/>
          　　<a href="/cesiumtest"><b>こちらからご確認ください</b></a>
          <br />
          </p>
          <h3>
            <font style={{color:"red"}}>テーマから選ぶ画面は作業中です</font>
          </h3><p>
          　・データがない項目を選択するとエラーが出ます。<br/>
          　・各ページへの移動には、<a href="/mdlist"><b>登録情報一覧</b></a>の各表左端のリンクをご利用ください。
          <br />
          </p>
          <h3>
            <font style={{color:"red"}}>都市を選び直した際には再読み込みが必要です</font>
          </h3><p>
          　・都市を選び直したあと、リロード（F5ボタン）でプレビュー画面を更新してください。
          <br />
          </p>


          <br/>
          <br/>

          <hr/>

          <h2>第16稿  - 12/25</h2>    <br/>    
          <h3>
kmlデータとの接続（２都市比較）
          </h3><p>
　・２都市比較のページでも、データとの接続を行いました。kmlもダウンロードできるようになりました。
          </p>

          <br/>
          <hr/>

          <h2>第15稿  - 12/24</h2>  <br/>      
          <h3>
            kmlデータとの接続
          </h3><p>
          　・データとの接続を行いました。kmlもダウンロードできるようになりました。<br />
          　・これにて、Ver3とVer6の参照は不要になります。（これまでお手数をおかけしました。）
          </p>
          <h3>
            凡例まわりのデザイン修正
          </h3><p>
          　・画像とテキストを組み合わせたデザインに修正しました。<br />
          　　<a href="/105"><b>こちらからご確認ください</b></a>
          <br />
          </p>
          <h3>
          「登録情報一覧」ページの更新</h3>
          <p>
          　・サイト内の情報を抽出し、見やすい表形式で整理しています。
          <br />
          　・データ更新方法についての解説をページ内に掲載しました。どなたでも修正や校正に関わっていただけます。
          <br />
          　　<a href="/mdlist"><b>「登録情報一覧」ページはこちらから</b></a>
          </p>


          <br/>
          <br/>


<img src="https://source.unsplash.com/featured/?mt.fuji" alt="" />



      </div>
    </Layout3>
  );
};

export default HomePage;

